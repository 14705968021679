import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import { reactive, watch } from 'vue';

import { noDifference } from '@exchange/helpers/object-diff';
import persistanceHelper from '@exchange/helpers/persistance-helper';
import type { firebaseSnapshotMetadata } from '@exchange/libs/firebase/src';
import { firebaseService } from '@exchange/libs/firebase/src';
import { SimpleToast, toastManagerInstance } from '@exchange/libs/toasts/src';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { currencyService, CurrencyType, featuredCurrencyService } from '@exchange/libs/utils/currency/src';
import { langsInfoService } from '@exchange/libs/utils/langs-info/src';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger, nonProdConsoleInfo } from '@exchange/libs/utils/simple-logger/src';

import Settings, { BESettings, defaultBESettings } from './account-settings-model';

interface ComplianceSettings {
  showRiskConfirmation: boolean | undefined;
  showTermAndConditions: boolean | undefined;
}

const settingsAreEqual = (currentValue, newValue) => {
  const curType = typeof currentValue;
  const newType = typeof newValue;

  if (curType !== newType) {
    return false;
  }

  if (curType !== 'object') {
    return currentValue === newValue;
  }

  return noDifference(currentValue, newValue);
};

class UserSettings {
  private readonly logPrefix = 'UserSettings: ';

  private readonly LSKey = 'PRO_SETTINGS';

  public settings = reactive({
    userSettings: new Settings(persistanceHelper.getObjFromJSON(this.LSKey, defaultBESettings)),
  });

  public complianceInfo = reactive<ComplianceSettings>({
    showRiskConfirmation: undefined,
    showTermAndConditions: undefined,
  });

  private handleRiskConfirmation = () => {
    this.complianceInfo.showRiskConfirmation = isEmpty(this.settings.userSettings.riskAgreement);
  };

  private handleTermsAndConditions = () => {
    this.complianceInfo.showTermAndConditions = isEmpty(this.settings.userSettings.termAndConditions);
  };

  private handleUserCompliance = async () => {
    this.handleRiskConfirmation();
    this.handleTermsAndConditions();
  };

  private handleLanguage = async () => {
    if (this.settings.userSettings.language) {
      langsInfoService.setLanguage(this.settings.userSettings.language);
    } else {
      this.setSettings({
        path: 'language',
        value: CONSTANTS.DEFAULT_CURRENCIES.fiat,
      });
    }
  };

  private handleCurrency = async () => {
    const settingsCurrency = this.settings.userSettings.currency;

    if (settingsCurrency && featuredCurrencyService.settingsCurrencies.value.includes(settingsCurrency)) {
      return currencyService.updateDefaultCurrencies({ [CurrencyType.FIAT]: settingsCurrency });
    }

    return this.setDefaultCurrency();
  };

  private setDefaultCurrency = async () => {
    const defaultCurrency = CONSTANTS.DEFAULT_CURRENCIES.fiat;

    this.setSettings({
      path: 'currency',
      value: defaultCurrency,
    }).then(() => this.showCurrencyResetToast(defaultCurrency));
  };

  private showCurrencyResetToast = (currency: string) => {
    toastManagerInstance.addToast({
      content: SimpleToast,
      props: {
        variant: 'default',
        title: 'modules.account.settings.currency.defaultChangedTitle',
        message: {
          tKey: 'modules.account.settings.currency.defaultChangedMessage',
          tKeyParams: { currency },
        },
      },
    });
  };

  private onSnapshotReceived = async (savedSettings: BESettings | undefined, snapshotMetadata: firebaseSnapshotMetadata | undefined) => {
    nonProdConsoleInfo(`${this.logPrefix}Snapshot received; fromCache: ${snapshotMetadata?.fromCache}`, savedSettings);
    const setData = () => {
      if (savedSettings) {
        this.settings.userSettings = new Settings(savedSettings);
        persistanceHelper.localstorageSet(this.LSKey, this.settings.userSettings);
      }
    };

    if (savedSettings) {
      if (!snapshotMetadata?.fromCache) {
        setData();
        this.handleUserCompliance();
        this.handleLanguage();
        this.handleCurrency();
      } else {
        const localKeys = Object.keys(this.settings.userSettings).sort();
        const incomingKeys = Object.keys(savedSettings).sort();

        if (!isEqual(localKeys, incomingKeys)) {
          setData();
          this.handleUserCompliance();
          this.handleLanguage();
          this.handleCurrency();
        }
      }
    } else {
      await firebaseService.getSettingsCollection().set(this.settings.userSettings);
      this.handleUserCompliance();
      this.handleLanguage();
      this.handleCurrency();
    }
  };

  async setupSubscriptions() {
    await this.subscribeToSettingUpdates();
    this.subscribeToAvailableCurrencies();
  }

  private subscribeToAvailableCurrencies() {
    watch(featuredCurrencyService.settingsCurrencies, () => {
      this.handleCurrency();
    });
  }

  private async subscribeToSettingUpdates() {
    try {
      await firebaseService.getSettingsCollection().createSubscription(this.onSnapshotReceived);
    } catch (e) {
      logger.log(`${this.logPrefix}Subscription failed`, e);

      await retryService.waitForNextRetryTick();
      await this.subscribeToSettingUpdates();
    }
  }

  async setSettings({ path, value }: { path: string; value: unknown }) {
    const currentValue = get(this.settings.userSettings, path);

    if (settingsAreEqual(currentValue, value)) {
      return;
    }

    set(this.settings.userSettings, path, value);
    persistanceHelper.localstorageSet(this.LSKey, this.settings.userSettings);
    await firebaseService.getSettingsCollection().update(path, value);
  }

  public onUpdateSettingFailed = (messageKey?: string) => {
    toastManagerInstance.addToast({
      content: SimpleToast,
      props: {
        variant: 'failed',
        title: 'fundamentals.error.title',
        message: messageKey || 'fundamentals.error.text',
      },
    });
  };
}

const settingsService = new UserSettings();

export default settingsService;
