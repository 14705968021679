import sortBy from 'lodash/sortBy';
import { computed, reactive, ref, watch } from 'vue';

import persistanceHelper from '@exchange/helpers/persistance-helper';
import { accountService } from '@exchange/libs/account/service/src';
import { authService } from '@exchange/libs/auth/service/src';
import { balanceService } from '@exchange/libs/balances/service/src';
import { eotcService } from '@exchange/libs/eotc/service/src';
import { accountRatioService, futuresService } from '@exchange/libs/futures/service/src';
import { ordersService } from '@exchange/libs/order/my-orders/service/src';
import { orderformService } from '@exchange/libs/order/order-form/service/src';
import PublicRest from '@exchange/libs/rest-api/public-api';
import { CONSTANTS } from '@exchange/libs/utils/constants/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

import Subaccount, { SubaccountSimple, SubaccountType } from './models/subaccount-model';

const hideLowCryptoValuesStorageKey = 'HIDE_LOW_SUBACCOUNT_BALANCES';

interface SubaccountsServiceState {
  list: Array<Subaccount>;
  balanceSearch: string;
  hideLowCryptoValues: boolean;
}

export enum SubaccountActionEmitEvent {
  cancelModal = 'cancel',
  confirmModal = 'confirm',
}

export class SubaccountsService {
  private state = reactive<SubaccountsServiceState>({
    list: [],
    balanceSearch: '',
    hideLowCryptoValues: persistanceHelper.getObjFromJSON(hideLowCryptoValuesStorageKey, false),
  });

  public fullList = computed(() => sortBy(this.state.list, ['created']) as Array<Subaccount>);

  public list = computed(() => this.fullList.value.filter((a) => a.isActive));

  public listWithInstantTrade = computed(() => {
    if (!this.list.value.length) {
      return [];
    }

    return [
      this.list.value[0], // always main
      new SubaccountSimple({
        type: SubaccountType.INSTANT_TRADE,
        name: CONSTANTS.INSTANT_TRADE_ACCOUNT_NAME,
        accountId: eotcService.accountIdInstant,
        // @ts-ignore vue UnwrapRef type removes _isBigNumber private props
        balances: eotcService.balances.value,
      }),
      ...this.list.value.slice(1),
    ] as Array<Subaccount>;
  });

  public getTotalFiatAmount = (id?: string) => {
    if (id === eotcService.accountIdInstant) {
      return eotcService.getTotalFiatAmount();
    }

    return balanceService.totalFiatValue.value;
  };

  public getTotalLockedFiatAmount = (id?: string) => {
    if (id === eotcService.accountIdInstant) {
      return eotcService.getTotalLockedFiatAmount();
    }

    return balanceService.totalLockedFiatValue.value;
  };

  public fetchList = async (): Promise<void> => {
    try {
      const [, { subaccounts }] = await Promise.all([eotcService.fetchBalances(), PublicRest.Subaccounts.getAll()]);

      this.state.list = subaccounts.map((e) => new Subaccount(e));
    } catch (error) {
      logger.warn('Fetching subaccounts failed', error);
    }
  };

  private pollListTimeoutID = 0;

  private pollListTimeoutMs = 300_000;

  public startListPolling = async () => {
    if (this.pollListTimeoutID) {
      this.stopListPolling();
    }

    await this.fetchList();

    const currentSubAccount = this.list.value.find((sa) => sa.accountId === accountService.accountId.value);

    if (currentSubAccount?.balances) {
      currentSubAccount.balances.forEach((balance) => {
        balanceService.setBalance({ balance });
      });
      balanceService.updateBalancesSnapshotReceived(true);
    }

    // @ts-ignore fix it
    this.pollListTimeoutID = setTimeout(this.startListPolling, this.pollListTimeoutMs);
  };

  public stopListPolling = () => {
    clearTimeout(this.pollListTimeoutID);
  };

  public balanceSearch = computed(() => this.state.balanceSearch);

  public updateBalanceSearch = (str: string) => {
    this.state.balanceSearch = str;
  };

  public setHideLowCryptoValues = (value: boolean) => {
    this.state.hideLowCryptoValues = value;
    persistanceHelper.localstorageSet(hideLowCryptoValuesStorageKey, value);
  };

  public fetchLimits = async () => PublicRest.Subaccounts.Limits.get();

  public userHasSubaccounts = computed(() => this.state.list.length > 1);

  public userHasActiveSubaccounts = computed(() => this.list.value.length > 1);

  public hideLowCryptoValues = computed(() => this.state.hideLowCryptoValues);

  public getAccountByProp = (propName: string) => (propValue: string | undefined) =>
    this.listWithInstantTrade.value.find((account) => account[propName] === propValue) as Subaccount | undefined;

  public getAccountById = (accountId: string | undefined) => this.getAccountByProp('accountId')(accountId);

  public isSwitchingSubaccount = ref(false);

  public switchTo = async (id: string) => {
    this.isSwitchingSubaccount.value = true;

    accountRatioService.reset();
    balanceService.reset();
    futuresService.resetLeverage();
    ordersService.reset();
    orderformService.hardReset({ prefillPriceUsingCurrentMarket: true });

    const switchToId = id === eotcService.accountIdInstant ? accountService.accountIdHolder.value : id;

    try {
      if (switchToId) {
        await authService.switchToSubaccount(switchToId);
      }

      accountService.setAccountUIId(id);
    } finally {
      this.isSwitchingSubaccount.value = false;
    }
  };

  public awaitSubaccountList = (): Promise<Array<Subaccount>> => {
    if (this.state.list.length) {
      return Promise.resolve(this.state.list as Array<Subaccount>);
    }

    return new Promise((resolve) => {
      const watchStopHandle = watch(
        () => this.state.list,
        // eslint-disable-next-line consistent-return
        (newValue) => {
          if (newValue) {
            watchStopHandle();
            return resolve(newValue as Array<Subaccount>);
          }
        },
      );
    });
  };

  public reset = () => {
    this.state.list = [];
  };

  public transfer = async (data: { amount: string; currencySymbol: string; source: string; sourceType: SubaccountType; destination: string; destinationType: SubaccountType }) => {
    const payload = {
      amount: data.amount,
      currency: data.currencySymbol,
      source: data.source,
      destination: data.destination,
    };

    if ([data.sourceType, data.destinationType].some((i) => i === SubaccountType.INSTANT_TRADE)) {
      await eotcService.transfer(payload);
    } else {
      await PublicRest.Subaccounts.Transfers.post(payload);
    }

    await this.fetchList();
  };

  public getSubaccountById = (accountId: string) => this.state.list.find((subaccount) => subaccount.accountId === accountId);
}

const subaccountsService = new SubaccountsService();

export default subaccountsService;
