<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import type { AccountRatioStatus } from '@exchange/libs/futures/service/src/lib/account-ratio';

const { t, te } = useI18n({ useScope: 'global' });

const props = defineProps({
  status: { type: Object as PropType<AccountRatioStatus>, required: true },
});

const title = computed(() => t(`modules.ribbons.accountRatio.${props.status}.title`));
const text = computed(() => (te(`modules.ribbons.accountRatio.${props.status}.text`) ? t(`modules.ribbons.accountRatio.${props.status}.text`) : ''));
</script>

<template>
  <div
    class="account-ratio-ribbon"
    :class="`account-ratio-ribbon--${status.toLowerCase()}`"
  >
    <div class="account-ratio-ribbon__legend">
      <div class="account-ratio-ribbon__legend-message">
        <strong>{{ title }}</strong> {{ text }}
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.account-ratio-ribbon {
  position: relative;
  display: flex;
  height: auto;
  min-height: 40px;
  padding: 0 48px;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgb(var(--v-theme-primary));
  color: var(--text-0);
  letter-spacing: 0.3px;

  &--derisk {
    background-color: rgb(var(--v-theme-warning));
  }

  &--liquidation {
    background-color: rgb(var(--v-theme-error));
  }

  .sm &,
  .xs & {
    flex-direction: column;
    justify-content: center;
    min-height: 0;
    padding: 0;
    white-space: normal;
  }

  &__legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-medium);

    .sm &,
    .xs & {
      flex-direction: column;
      justify-content: center;
      white-space: normal;
    }
  }

  &__legend-message {
    margin: 8px 20px 8px 0;

    .sm &,
    .xs & {
      margin: 10px 50px;
      text-align: center;
    }
  }
}
</style>
