import { SubaccountType } from './models/subaccount-model';

export const getAccountTypeLabel = (type: SubaccountType): string => {
  switch (type) {
    case SubaccountType.STANDARD:
      // This type is deprecated and will be removed in the future
      return 'Spot';
    case SubaccountType.INSTANT_TRADE:
      return 'Swap';
    case SubaccountType.SPOT:
      return 'Spot';
    case SubaccountType.FUTURES:
      return 'Futures';
    default:
      return type;
  }
};
