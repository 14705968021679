<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import { OrderStatusesRejected, type OrderStatusRejected } from '@exchange/libs/order/shared-model/src/lib/order-essentials';

import SimpleToast from '../SimpleToast.vue';

const props = defineProps({
  event: { type: Object as PropType<{ reason: string }>, required: true },
});
const emit = defineEmits<(e: 'remove') => void>();

const { te } = useI18n({ useScope: 'global' });

const rowReason = computed(() => props.event.reason);

const reason = computed(() => {
  if (te(`modules.myOrders.toasts.rejected.reason.${rowReason.value}`)) {
    return `modules.myOrders.toasts.rejected.reason.${rowReason.value}`;
  }

  return 'modules.myOrders.toasts.rejected.reason.DEFAULT';
});

const title = computed(() => {
  if (OrderStatusesRejected.includes(rowReason.value as OrderStatusRejected)) {
    return 'modules.myOrders.toasts.rejected.titleRejected';
  }

  return 'modules.myOrders.toasts.rejected.title';
});
</script>

<template>
  <simple-toast
    variant="failed"
    icon-name="danger"
    :title="title"
    :message="reason"
    @remove="emit('remove')"
  />
</template>
