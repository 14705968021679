<script lang="ts" setup>
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';

import { accountService } from '@exchange/libs/account/service/src';
import useMQBreakpoints from '@exchange/libs/composables/shared/src/lib/useMQBreakpoints';
import { accountRatioService } from '@exchange/libs/futures/service/src';
import { AccountRatioStatus } from '@exchange/libs/futures/service/src/lib/account-ratio';
import { marketService, MarketState } from '@exchange/libs/market/service/src';
import { launchdarkly } from '@exchange/libs/utils/launchdarkly/src';
import { SWAppUpdateChecker } from '@exchange/libs/utils/service-worker-update-checker/src';
import wsEmitter, { onMaxConnectAttempt, type WebSocketManagerName } from '@exchange/libs/utils/wss/src/lib/websocket-event-bus';

import AccountRatioRibbon from './account/AccountRatioRibbon.vue';
import ConnectionRibbon from './ConnectionRibbon.vue';
import MarketRibbon from './MarketRibbon.vue';
import MarketPromotionRibbon from './new-market-promotion/MarketPromotionRibbon.vue';
import useMarketPromotionRibbon from './new-market-promotion/usMarketPromotionRibbon';
import SecurityRibbon from './security/SecurityRibbon.vue';
import UpdateRibbon from './UpdateRibbon.vue';

const props = defineProps({
  delay: { type: Number, default: 10_000 },
  marketId: { type: String, required: false },
});

const route = useRoute();
const { isXS } = useMQBreakpoints();
const { showMarketPromotionRibbon, onCloseMarketPromotion } = useMarketPromotionRibbon();

const { securityShow } = accountService;
const { status: accountRatioStatus } = accountRatioService;
const { getMarket, getMarketName, getMarketState } = marketService;

let delayTimer: ReturnType<typeof setTimeout> | null = null;
const delayIsFinished = ref(false);
const showWsMaxConnectAttemptSpot = ref(false);
const showWsMaxConnectAttemptEotc = ref(false);

const market = computed(() => getMarket(props.marketId));
const marketState = computed(() => getMarketState(market.value));
const marketName = computed(() => getMarketName(market.value));

const showMarketRibbon = computed(() => marketState.value && [MarketState.SUSPENDED, MarketState.IN_MAINTENANCE].includes(marketState.value));

const { verification } = launchdarkly.flags;

const isSWUpdateAvailable = computed(() => SWAppUpdateChecker.serviceWorkerUpdated.value);
const isSpotPage = computed(() => route.name === 'spot');
const isEotcPage = computed(() => route.name === 'portfolio');

const showVerify = securityShow.verify;

const onCloseConnectionRibbon = (name: WebSocketManagerName) => {
  if (name === 'fastWebSocketManager') {
    showWsMaxConnectAttemptSpot.value = false;
  }

  if (name === 'eotcWebSocketManager') {
    showWsMaxConnectAttemptEotc.value = false;
  }
};
const showMaxConnectAttempt = (name: WebSocketManagerName) => {
  if (name === 'bunWebSocketManager') {
    return;
  }

  if (name === 'fastWebSocketManager') {
    showWsMaxConnectAttemptSpot.value = true;
  }

  if (name === 'eotcWebSocketManager') {
    showWsMaxConnectAttemptEotc.value = true;
  }
};
const hideMaxConnectAttempt = (name: WebSocketManagerName) => {
  if (name === 'bunWebSocketManager') {
    return;
  }

  if (name === 'fastWebSocketManager') {
    showWsMaxConnectAttemptSpot.value = false;
  }

  if (name === 'eotcWebSocketManager') {
    showWsMaxConnectAttemptEotc.value = false;
  }
};
const listenOnMaxConnectAttempt = onMaxConnectAttempt(showMaxConnectAttempt);
const listenOnConnected = onMaxConnectAttempt(hideMaxConnectAttempt);

wsEmitter.on('max-connect-attempt', listenOnMaxConnectAttempt);
wsEmitter.on('connected', listenOnConnected);

onMounted(() => {
  delayTimer = setTimeout(() => {
    delayIsFinished.value = true;
  }, props.delay);
});

onBeforeUnmount(() => {
  if (delayTimer) clearTimeout(delayTimer);
  wsEmitter.off('max-connect-attempt', listenOnMaxConnectAttempt);
  wsEmitter.off('connected', listenOnConnected);
});
</script>

<template>
  <ot-transition-collapse>
    <!-- We don't want to delay the account ratio ribbon being shown -->
    <account-ratio-ribbon
      v-if="accountRatioStatus === AccountRatioStatus.DERISK || accountRatioStatus === AccountRatioStatus.LIQUIDATION"
      :status="accountRatioStatus"
    />
    <template v-if="delayIsFinished">
      <update-ribbon
        v-if="isSWUpdateAvailable"
        key="update-ribbon"
        class="ribbon-section"
      />
      <connection-ribbon
        v-else-if="showWsMaxConnectAttemptSpot && isSpotPage"
        key="connection-ribbon-exchange"
        type="exchange"
        class="ribbon-section"
        @close="onCloseConnectionRibbon('fastWebSocketManager')"
      />
      <connection-ribbon
        v-else-if="showWsMaxConnectAttemptEotc && isEotcPage"
        key="connection-ribbon-eotc"
        type="eotc"
        class="ribbon-section"
        @close="onCloseConnectionRibbon('eotcWebSocketManager')"
      />
      <market-promotion-ribbon
        v-else-if="showMarketPromotionRibbon"
        key="market-promotion-ribbon"
        class="ribbon-section"
        @close-market-promotion="onCloseMarketPromotion"
      />
      <market-ribbon
        v-else-if="showMarketRibbon"
        key="market-ribbon"
        class="ribbon-section"
        :market-name="marketName"
        :market-state="marketState"
      />
      <security-ribbon
        v-else-if="showVerify && !isXS"
        key="security-ribbon"
        class="ribbon-section"
        :verification="verification"
      />
    </template>
  </ot-transition-collapse>
</template>
